body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #212121;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.m-1{
  margin: 5px;
}

.mt-1{
  margin-top: 5px;
}

.mb-1{
  margin-bottom: 5px;
}

.m-2{
  margin: 10px;
}

.mt-2{
  margin-top: 10px;
}

.mb-2{
  margin-bottom: 10px;
}

.container{
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 1200px;
  margin: 25px auto;
  padding: 25px 50px;
  background: #fff;
  border-radius: 3px;
}

.container input{
  margin-top:10px;
  padding: 5px;
  font-size: 16px;
}

.input-item{
  margin-top: 10px;
  padding: 15px 10px;
  background: #c9c9c9;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
}

.once{
  background-color: #ffc633;
}

.monthly{
  background-color: #33adff;
}

.return-value{
  background-color: #57dd3d;
}

.input-min-max-werte {
  display: flex;
  justify-content: space-between;
}

input.input-numerical {
  width: 200px;
  padding: 5px;
  border: 1px solid #bfbfbf;
  font-size: 20px;
  margin-top: 10px;
}

.gesamtertrag{
  font-size: 20px;
}
.gesamtertrag-betrag{
  font-size: 20px;
  padding: 15px;
  background-color: #f2f2f2;
}

.results-wrapper {
  margin-top: 20px;
}

.result-set {
  display: flex;
  grid-gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.result {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  font-size: 20px;
  border-bottom: 1px solid #cccccc;
  margin-top: 5px;
}

.total-set {
  max-width: 500px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 15px;
  background-color: #f2f2f2;
  margin-bottom: 20px;
}

.input-wert {
  font-size: 18px;
}

#chart {
  background: #fff;
  width: 90%;
}

svg rect {
  fill: orange;
}

svg text {
  fill:rgb(32, 29, 29);
  font: 12px sans-serif;
  text-anchor: end;
}

@media only screen and (max-width: 1030px) {

}

@media only screen and (max-width: 700px) {
  .container{
    width: 95%;
    padding: 25px 15px;
  }

  .result-set, .total-set{
    flex-direction: column;
    align-items: flex-start;
  }

  .data-total{
    flex-direction: column;
    gap:5px;
    margin:15px;
  }
}